/* storybook-check-ignore */
import React, { useEffect, useState } from 'react';

import { useAuth } from '@opendoor/auth-fe';
import { Box } from '@opendoor/bricks/core';
import { IAddressInputAddress } from '@opendoor/cinderblocks/growth/AddressInput/AddressInput';
import { globalObservability } from '@opendoor/observability/slim';
import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';

import { OdProtosCasaData_Result } from '__generated__/athena';

import { handleAddressSubmit } from 'components/shared/SellerFlowAddressInput';

import useQueryParams from 'helpers/queryParams';

import { useObservability } from '../../../src/helpers/observability';
import { AddressSearchLocation, TrackingTaxonomy } from '../../components/globals';
import { AddressConfirmationModal } from '../../components/landing-pages-v2/AddressConfirmationModal';
import NovoAddressInput, { AutoCompletePrediction } from '../../components/novo/NovoAddressInput';
import {
  ADDRESS_INVALID_RESULT,
  getAddressValidationExperimentVariant,
  useAddressValidationContext,
} from '../../helpers/AddressValidationContext';
import {
  ALLOWED_TYPES,
  buildAutocompleteUrl,
  buildPlacesUrl,
  generateGoogleMapsSessionToken,
} from '../../helpers/googleMaps';
import { listUnits, standardizeAddress } from '../api';

const DEBOUNCE_INVOKE_WAIT_MS = 100;
const UNIVERSAL_NIL_ADDRESS = '6aae647b-d603-5533-8464-c42c658b101a';

interface NewSellerFlowAddressInputProps {
  inNewAddressEntryExperiment?: boolean;
  inputLocation: AddressSearchLocation;
  trackingTaxonomy: TrackingTaxonomy;
  analyticsPrefix?: string;
  placeholder: string;
  hideLabel?: boolean;
}

export const gateManualAddressVerification = async ({
  forceRedirect,
  address,
  customerUUID,
  setAddressValidationExperimentVariant,
}: {
  forceRedirect: boolean;
  address?: IAddressInputAddress;
  customerUUID?: string | null;
  setAddressValidationExperimentVariant: (variant: string) => void;
}) => {
  let moreResult: OdProtosCasaData_Result[] = [];
  if (forceRedirect || !address) {
    const addressValidationExperimentVariant = await getAddressValidationExperimentVariant(
      customerUUID,
    );
    setAddressValidationExperimentVariant(addressValidationExperimentVariant);
    return { shouldRedirect: true, destination: '/manual-address-entry' };
  }

  const standardizeAddressResponse = await standardizeAddress(address);
  if (
    !standardizeAddressResponse ||
    !standardizeAddressResponse.data ||
    standardizeAddressResponse.errors ||
    standardizeAddressResponse.data?.casa.findByFields?.address?.uuid === UNIVERSAL_NIL_ADDRESS
  ) {
    const addressValidationExperimentVariant = await getAddressValidationExperimentVariant(
      customerUUID,
    );
    setAddressValidationExperimentVariant(addressValidationExperimentVariant);
    moreResult = ['ADDRESS_UNKNOWN'];
    return {
      shouldRedirect: true,
      destination: '/manual-address-entry',
      standardizeAddress: address,
      moreResult,
    };
  }

  const casaAddress = standardizeAddressResponse.data?.casa.findByFields?.address;

  // it's possible for an address to be correct with or without a unit number
  // in these cases, casa won't include the ADDRESS_MISSING_SUB_PREMISE flag, so we have to call
  // a separate endpoint to fetch all possible units for that address.
  if (
    !casaAddress.result.includes('ADDRESS_MISSING_SUB_PREMISE') &&
    casaAddress.unitNumber === ''
  ) {
    if (await doesAddressHaveMultipleUnits(casaAddress.uuid)) {
      moreResult = ['ADDRESS_MISSING_SUB_PREMISE'];
    }
  }

  if (ADDRESS_INVALID_RESULT.some((error) => casaAddress?.result.includes(error))) {
    const addressValidationExperimentVariant = await getAddressValidationExperimentVariant(
      customerUUID,
    );
    setAddressValidationExperimentVariant(addressValidationExperimentVariant);
    if (addressValidationExperimentVariant === 'treatment') {
      return {
        shouldRedirect: true,
        destination: '/manual-address-entry',
        standardizeAddress: casaAddress,
        moreResult,
      };
    }
  }
  return { shouldRedirect: false, standardizeAddress: casaAddress, moreResult };
};

const doesAddressHaveMultipleUnits = async (addressUuid: string) => {
  const response = await listUnits(addressUuid);
  return response?.addresses?.length >= 2;
};

const NewSellerFlowAddressInput: React.FC<NewSellerFlowAddressInputProps> = ({
  inputLocation,
  trackingTaxonomy,
  hideLabel,
  inNewAddressEntryExperiment,
  placeholder,
}) => {
  const router = useRouter();
  const { user } = useAuth();
  const { trackEvent } = useObservability();

  const [isConfirmationModalOpen, setIsComfirmationModalOpen] = useState(false);
  const [predictions, setPredictions] = useState<AutoCompletePrediction[]>([]);

  const {
    isPredictionSelectLoading,
    validatedAddress,
    setValidatedAddress,
    setAddressResults,
    setIsPredictionSelectLoading,
    setFirstPrediction,
    setAddressValidationExperimentVariant,
    setPartnerReferralId,
  } = useAddressValidationContext();
  const [searchTerm, setSearchTerm] = useState('');

  const { partner_referral, pa } = useQueryParams();
  const sessionToken = generateGoogleMapsSessionToken();

  useEffect(() => {
    if (partner_referral) {
      setPartnerReferralId(partner_referral as string);
    }
    if (pa) {
      setSearchTerm(pa as string);
      fetchAutocompleteOptions(pa as string);
    }
  }, [partner_referral, pa]);

  const fetchAutocompleteOptions = debounce((input: string) => {
    if (!input) {
      setPredictions([]);
      return;
    }
    fetch(buildAutocompleteUrl(input, sessionToken))
      .then((res) => res.json())
      .then((res) => {
        res.predictions.forEach((result: AutoCompletePrediction) => {
          result.description = result.description.replace(', USA', '');
        });

        const autocompleteOptions = res.predictions.filter((result: AutoCompletePrediction) => {
          result.source = res.source;
          if (res.source === 'google') {
            const terms = result.terms ?? [];
            const resultAllowedTypes = (result.types ?? []).filter((value) =>
              ALLOWED_TYPES.includes(value),
            );
            return (
              (terms.length >= 5 && resultAllowedTypes.length != 0) ||
              (terms.length === 4 && terms[0].value.match(/^\d+\S*\s\S+\s/) !== null)
            );
          }
          return result;
        });
        setPredictions(autocompleteOptions);
        if (autocompleteOptions.length > 0) {
          setFirstPrediction(autocompleteOptions[0]);
        }
      })
      .catch(() => setPredictions([]));
  }, DEBOUNCE_INVOKE_WAIT_MS);

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const address = e.target.value;

    setSearchTerm(address);
    fetchAutocompleteOptions(address);
    if (address === '') setPredictions([]);
  };

  const addressVerification = async (address: IAddressInputAddress) => {
    const result = await gateManualAddressVerification({
      forceRedirect: false,
      address,
      customerUUID: user?.customerUuid,
      setAddressValidationExperimentVariant,
    });
    const combinedResults = [
      ...(result?.standardizeAddress?.result || []),
      ...(result?.moreResult || []),
    ];
    setAddressResults(combinedResults);
    setValidatedAddress({
      id: result?.standardizeAddress?.uuid,
      street1: result?.standardizeAddress?.street || result?.standardizeAddress?.street1,
      city: result?.standardizeAddress?.city,
      state: result?.standardizeAddress?.state,
      postal_code:
        result?.standardizeAddress?.postalCode || result?.standardizeAddress?.postal_code,
      unit: result?.standardizeAddress?.unitNumber || result?.standardizeAddress?.unit,
    });
    if (result.shouldRedirect && result.destination) {
      router.push(result.destination);
    } else {
      setIsComfirmationModalOpen(true);
    }
  };

  const handlePredictionSelect = async (prediction: AutoCompletePrediction) => {
    setSearchTerm(prediction.description);
    setIsPredictionSelectLoading(true);

    const url =
      prediction.source === 'google'
        ? buildPlacesUrl({
            source: 'google',
            placeId: prediction.place_id,
            sessionToken,
          })
        : buildPlacesUrl({
            source: 'casa',
            addressToken: prediction.addressToken,
            sessionToken,
          });

    try {
      const response = await fetch(url);
      const address = (await response.json()) as IAddressInputAddress;

      if (inNewAddressEntryExperiment) {
        await addressVerification(address);
      } else {
        handleAddressSubmit(
          address,
          trackEvent,
          'SELL_DIRECT',
          trackingTaxonomy,
          undefined,
          undefined,
          inputLocation,
          undefined,
          partner_referral as string,
          undefined,
          user?.customerUuid,
        );
      }
    } catch (e) {
      globalObservability.getSentryClient().captureException?.(e);
    } finally {
      setIsPredictionSelectLoading(false);
    }
  };

  return (
    <Box flex={1}>
      <NovoAddressInput
        inNewAddressEntryExperiment={inNewAddressEntryExperiment}
        id="home-address-input"
        placeholder={placeholder}
        hideLabel={hideLabel}
        disabled={false}
        prediction={{
          predictions: predictions,
          setPredictions: setPredictions,
          searchTerm: searchTerm,
          setSearchTerm: setSearchTerm,
          handlePredictionSelect: handlePredictionSelect,
          onChange: handleAddressChange,
        }}
        product="SELL_DIRECT"
      />
      {isConfirmationModalOpen &&
        inNewAddressEntryExperiment &&
        !isPredictionSelectLoading &&
        validatedAddress && (
          <AddressConfirmationModal
            setIsOpen={setIsComfirmationModalOpen}
            isOpen={isConfirmationModalOpen}
            customerUUID={user?.customerUuid}
            partnerReferralId={partner_referral as string}
            initialAddress={validatedAddress}
          />
        )}
    </Box>
  );
};

export default NewSellerFlowAddressInput;
